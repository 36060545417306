import styled, { createGlobalStyle } from 'styled-components';

const UI = {
    colors: {
        primary: 'black',
        bgMenu: `rgba(255,255,255,0.8)`
    }
}

export const Breakpoints = null;

export const GlobalStyle = createGlobalStyle`
    html {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }
    body {
        margin: 0;        
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        background-color: #fff;
    }
    * {
        box-sizing: border-box;   
    }
    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    h2 {
        font-size: 1.5em;
    }
    textarea, select, input, button { outline: none; }
`



export const Table = styled.table`
    width: 100%;
    margin-bottom: 20px;
    thead {
        text-align: left;
        vertical-align: top;
        background-color: rgba(0,0,0,.04);
        th {
            padding: 10px;
        }
    }
    tbody {
        text-align: left;
        vertical-align: top;
        td {
            padding: 10px;
        }
    }
`

export const H1Styled = styled.h1`
    display:flex;
    align-items:center;
    font-size: 34px;
    margin:0;
    img {
        margin-left: 10px;
    }
`
export const SliderImage = styled.div`
    outline: none;   
    >div {
        height: 600px;
        background-position: center;
    }
`

export const TopLogoLink = styled.a`
    display: flex;
    align-items: center;
`
export const MenuStyle = {
    Main: styled.div`
    display: flex;
    align-items: center;
`,
    Link: styled.a`
    display: flex;
    align-items: center;
`
}
export const MenuLink = styled.li`
  
    a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
    }
`
export const ImageBox = styled.div`
    padding: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
    &:hover {
        transform: scale(1.1);
        z-index:2;
        >div {
            box-shadow: 1px 1px 23px -5px black;
        }
    }
`

export const FooterTitle = styled.div`
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
`
export const FooterItem = styled.div`
    justify-content: center;
    display: flex;
    a {
        color: #fff;
        font-size: 13px;
    }
    >div {
       &:nth-child(1) {
        margin-right: 15px;
       }
       &:nth-child(2) {
            color: #fff;
            font-size: 13px;
           >div {
               &:nth-child(1) {
                    font-weight: 700;
               }
           }
       }
   }
`

export const FooterItemBottom = styled.div`
    color: hsla(0,0%,100%,.5);
    margin: 8px 0px;
    font-size: 12px;
    justify-content: center;
    display: flex;
    text-align: center;
`


export default UI;