import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GlobalStyle } from "../styles/style";

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            locale
          }
        }
      }
    `}
        render={data => (
            <>
                <GlobalStyle />
                {children}
            </>
        )}
    />
)

export default Layout
